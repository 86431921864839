import React from 'react';
import { ReactComponent as Logo } from '../logo.svg';
import './App.scss';

function App() {
  return (
    <div className="App" aria-label="app-root">
      <div className="iconHolder">
        <div className="bounceY">
          <div className="bounceX">
            <div className="App-logo">
              <Logo />
            </div>
          </div>
        </div>
      </div>
      <div className="iconHolder">
        <div className="bounceYegg">
          <div className="bounceXegg">
            <div className="egg">🥚</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
